import styled from "styled-components";
import { Classes } from "@blueprintjs/core";
import { lighten } from "polished";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  firstBreakpoint,
  secondBreakpoint,
  thirdBreakpoint,
} from "../../../windowBreakpoints";

export const StyledSearchInputWrapper = styled.div<{ isDisabled: boolean }>`
  position: relative;
  width: 100%;
  max-width:800px;
  margin: auto;
  opacity: ${props => props.isDisabled ? .6 : 1};
  & input {
    cursor: ${props => props.isDisabled ? "not-allowed" : "text"};
  }
  & svg {
    cursor: ${props => props.isDisabled ? "not-allowed" : "default"};
  }
  @media (max-width: ${secondBreakpoint}px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const StyledSearchInput = styled.input`
  display: flex;
  padding: 10px;
  padding-left: 35px;
  height: 40px;
  width: 100%;
  font-size: .9rem;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid #8C8989;
  outline: none;
  @media (max-width: ${thirdBreakpoint}px) {
    height: 35px;
  }
`;

export const StyledSearchIconWrapper = styled.div`
  position: absolute;
  display: flex;
  left: 13px;
  top: 50%;
  color: rgba(0, 0, 0, .7);
  transform: translateY(-50%);
  max-width:800px;
  margin:auto;
`;
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

`;

export const StyledEllipsisWrapper = styled.div`
  color: rgba(0, 0, 0, .6);
  cursor: pointer;
  transition: color .2s ease-out;
  :hover {
    color: rgba(0, 0, 0, .9);
  }
  & .${Classes.POPOVER_TARGET} {
    display: flex;
    align-items: center;
  }
`;

export const StyledTable = styled.table`
  margin-top:20px!important;
  & th {
    color: #fff;
    background-color: #00A1E0;
    border-bottom: none !important;
    font-family:'Roboto';
    font-size:17px;
    font-weight:400;
  }
  & th, & td {
    padding-left: 15px !important;
  }
  & thead {
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  & th {
    :first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    :last-of-type {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  @media only screen and (max-width: 480px) {
    th {
      font-size: 13px;
    }
  }
`;

export const StyledNoPlayersPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
  height: 150px; 
  width: 100%;
  font-weight: 600;
  border-radius: 10px;
  border: 1px solid ${lighten(.2, "#4C7BF4")};
  background-color: ${lighten(.35, "#4C7BF4")};
  @media (max-width: 900px) {
    height: 120px; 
  }
`;
