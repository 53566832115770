import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaSearch } from "react-icons/fa";
import { Text } from "@blueprintjs/core";
import { Maybe } from "common-types";
import { Link } from "gatsby";

import LeaderboardTable from "../LeaderboardTable";
import StyledBtn from "../../sharedStyledComponents/styledBtn";
import { CreatePlayer, DeletePlayer } from "../../containers/Layout";
import { OpenEditPlayerDialog } from "../Layout";
import { ILeaderboard, IPlayer, ITeam } from "../../stateUtils";
import {
  StyledContainer,
  StyledForm,
  StyledNameInput,
  StyledSelect,
  StyledScoreInput,
  StyledLeaderboardHeader,
  StyledLeaderboardTitle,
  StyledSearchInputWrapper,
  StyledSearchInput,
  StyledSearchIconWrapper,
  StyledLeaderboardTableWrapper,
} from "./styles";
import firebase from '../firebase';

export interface IRankedPlayerItem {
  player: IPlayer;
  rank: number;
}

export interface IRankedTeamItem {
  team: ITeam;
  rank: number;
}

interface IProps {
  leaderboard: Maybe<ILeaderboard>;
  players: IPlayer[];
  teamId: string;
  teams: ITeam[];
  createPlayer: CreatePlayer;
  deletePlayer: DeletePlayer;
  openEditPlayerDialog: OpenEditPlayerDialog;
  sidebarEnabled: boolean;
}

const sortPlayers = (players: IPlayer[]) => (
  players
    .sort((a, b) => {
      // sort by score in descending order
      if ((a.score - a.handicap) === (b.score - b.handicap)) {
        // Need to figure out how to Show the same rank twice 
        if (a.lastName > b.lastName) {
          return 0;
        } else if (a.lastName < b.lastName) {
          return 0;
        } else {
          return 0;
        }
      } else if ((a.score - a.handicap) < (b.score - b.handicap)) {
        return -1;
      } else {
        return 1;
      }
    })
);

const sortTeams = (teams: []) => (
  teams
    .sort((a, b) => {
      if ((a.score - a.handicap) < b.score - b.handicap) {
        return -1;
      } else {
        return 1;
      }
    })
);

const mapSortedPlayersToRank = (players: IPlayer[]) => (
  players.map((player, index) => ({ rank: index + 1, player }))
);

const filterPlayersBySearchText = (searchText: string, players: IRankedPlayerItem[]) => (
  players
    .filter(({ player }) => `${player.firstName} ${player.lastName}`
      .toLowerCase()
      .includes(searchText.trim().toLowerCase())
    )
);

const mapSortedTeamsToRank = (players: IPlayer[], teams: ITeam[]) => {
  const groupedTeams = groupTeamPlayers(players)
  const teamScore = addTeamScores(groupedTeams, 'score')
  const teamHandicap = addTeamScores(groupedTeams, 'handicap')
  const teamScores = combineObjectsIntoArray(teamScore, teamHandicap)

  const teamScoresWithName = teamScores.map((teamScore) => {
    return { ...teamScore, ...teams.filter(team => team.id === teamScore.teamId)[0]}
  })

  return sortTeams(teamScoresWithName).map((team, index) => ({ rank: index + 1, team }))
};

const addPlayerScores = (players: IPlayer[], scoreType: string) => {
  const scores = Object.values(players).map(player => player[`${scoreType}`])
  const scoresTotal = scores.reduce((a, b) => (parseInt(a) + parseInt(b)))
  return scoresTotal / scores.length
};

const groupTeamPlayers = (teams: IPlayer[]) => {
  return teams.reduce((acc, obj) => {
    const key = obj['teamId'];
    if (!acc[key]) {
       acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

const addTeamScores = (groupedTeams: object, scoreType: string) => {
  let teamScores = {}

  for(const key in groupedTeams) {
    teamScores[key] = { [scoreType]: addPlayerScores(groupedTeams[key], scoreType) }
  }

  return teamScores;
};


const combineObjectsIntoArray = (obj1, obj2) => {
  let mergedArr = []
  for(const key in obj1) {
    mergedArr.push({ ...{ teamId: key }, ...obj1[key], ...obj2[key] })
  }
  return mergedArr;
}

const playersWithTeamInfo = (players, teams) => {
  return players.map((player) => {
    return { ...player, ...teams.filter(team => team.id === player.teamId)[0] }
  })
}

export default ({
  leaderboard,
  teamId,
  teams,
  createPlayer,
  deletePlayer,
  players,
  openEditPlayerDialog,
  sidebarEnabled,
}: IProps) => {
  const [searchText, setSearchText] = useState("");
  const [newPlayer, setNewPlayer] = useState({ 
    firstName: "", 
    lastName: "", 
    coursePlayed:"", 
    teamId:"", 
    handicap:"", 
    score: "",
    leaderboardId: "",
    valid:"",
  })

  if (!leaderboard) return null;
  return (
    <StyledContainer sidebarEnabled={sidebarEnabled}>
    
      <StyledLeaderboardTableWrapper>
        <LeaderboardTable
          playersWithRank={
            searchText.trim() ? (
              filterPlayersBySearchText(
                searchText,
                mapSortedPlayersToRank(
                  sortPlayers(playersWithTeamInfo(players, teams))
                )
              )
            ) : mapSortedPlayersToRank(
              sortPlayers(playersWithTeamInfo(players, teams))
            )
          }
          teamsWithRank={
            mapSortedTeamsToRank(players, teams)
          }
          deletePlayer={deletePlayer}
          openEditPlayerDialog={openEditPlayerDialog}
          hasSearchText={!!searchText.trim()}
        />
      </StyledLeaderboardTableWrapper>

      <StyledSearchInputWrapper isDisabled={!players.length}>
          <StyledSearchIconWrapper>
            <FaSearch />
          </StyledSearchIconWrapper>
          <StyledSearchInput
            disabled={!players.length}
            onChange={event => (
              setSearchText((event.target as HTMLInputElement).value)
            )}
            placeholder="Search players by name..."
          />
        </StyledSearchInputWrapper>
        
    </StyledContainer>
  );
};
