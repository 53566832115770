import styled from "styled-components";
import { Classes } from "@blueprintjs/core";
import { secondBreakpoint } from "../../../windowBreakpoints";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: .8rem;
  overflow: hidden;
  @media (max-width: ${secondBreakpoint}px) {
    overflow: auto;
  }
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  @media (max-width: ${secondBreakpoint}px) {
    height: max-content;
  }
`;

export const StyledEllipsisWrapper = styled.div`
  color: rgba(0, 0, 0, .6);
  cursor: pointer;
  transition: color .2s ease-out;
  :hover {
    color: rgba(0, 0, 0, .9);
  }
  & .${Classes.POPOVER_TARGET} {
    display: flex;
    align-items: center;
  }
`;