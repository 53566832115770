import React from "react";
import ReactDOM from 'react-dom';

import { Tooltip, Popover, Menu, MenuItem } from "@blueprintjs/core";
import { FaEllipsisV } from "react-icons/fa";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { IRankedPlayerItem, IRankedTeamItem } from "../Leaderboard";
import { DeletePlayer } from "../../containers/Layout";
import { OpenEditPlayerDialog } from "../Layout";
import {
  StyledContainer,
  StyledEllipsisWrapper,
  StyledTable,
  StyledNoPlayersPlaceholder,
} from "./styles";

interface IProps {
  teamsWithRank: IRankedTeamItem[]
  playersWithRank: IRankedPlayerItem[];
  deletePlayer: DeletePlayer;
  openEditPlayerDialog: OpenEditPlayerDialog;
  hasSearchText: boolean;
}

export default ({
  teamsWithRank,
  playersWithRank,
  deletePlayer,
  openEditPlayerDialog,
  hasSearchText
}: IProps) => {

  const leaderboardData = playersWithRank.map(({ player, rank }) => {
    return {
      rank: rank,
      playerInfo: player,
      playerFirst: player.firstName,
      playerLast: player.lastName,
      playerCoursePlayed: player.coursePlayed,
      playerTeam: player.name,
      handicap: parseInt(player.handicap),
      score: parseInt(player.score),
      netScore: parseInt(player.score) - parseInt(player.handicap)
    }
  })

  function playerFormatter(cell) {

    return (
      <span>{ cell.firstName[0] }. { cell.lastName } <small>
      { cell.coursePlayed } <span> { cell.name },<i>{ cell.company }</i></span>
      </small>
      </span>
    );
  }
  function targetUI(e) {
    e.preventDefault();
    let target_ui = document.querySelector('.ui_container');
    target_ui.classList.toggle('active');
  }

  function targetRank(e) {
    e.preventDefault();
    let target_rank = document.querySelector('.react-bootstrap-table th.sortable.rank');
    target_rank.click();
  }

  function targetFirstName(e) {
    e.preventDefault();
    let target_first = document.querySelector('.react-bootstrap-table th.sortable.playerFirst');
    target_first.click();
  }

  function targetLastName(e) {
    e.preventDefault();
    let target_last = document.querySelector('.react-bootstrap-table th.sortable.playerLast');
    target_last.click();
  }
  function targetCoursePlayed(e) {
    e.preventDefault();
    let target_course = document.querySelector('.react-bootstrap-table th.sortable.coursePlayed');
    target_course.click();
  }
  function targetHandicap(e) {
    e.preventDefault();
    let target_handicap = document.querySelector('.react-bootstrap-table th.sortable.handicap');
    target_handicap.click();
  }
  function targetTeam(e) {
    e.preventDefault();
    let target_team = document.querySelector('.react-bootstrap-table th.sortable.playerTeam');
    target_team.click();
  }
  function targetGross(e) {
    e.preventDefault();
    let target_score = document.querySelector('.react-bootstrap-table th.sortable.score');
    target_score.click();
  }
  function targetNet(e) {
    e.preventDefault();
    let target_net = document.querySelector('.react-bootstrap-table th.sortable.netScore');
    target_net.click();
  }

  const leaderboardColumns = [{
    dataField: 'rank',
    text: 'Rank',
    sort: true,
    headerClasses: 'rank',
  },{
    dataField: 'playerInfo',
    text: 'Player Info',
    sort: true,
    formatter: playerFormatter,
    headerClasses: 'hidden_desktop',

  },{
    dataField: 'playerFirst',
    text: '',
    sort: true,
    headerClasses: 'hidden playerFirst',
  },{
    dataField: 'playerLast',
    text: '',
    sort: true,
    headerClasses: 'hidden playerLast',
  },{
    dataField: 'playerTeam',
    text: '',
    sort: true,
    headerClasses: 'hidden playerTeam',
  },{
    dataField: 'playerCoursePlayed',
    text: '',
    sort: true,
    headerClasses: 'hidden coursePlayed',
  },{
    dataField: 'handicap',
    text: 'Handicap',
    sort: true,
    headerClasses: 'handicap',

  },{
    dataField: 'score',
    text: 'Gross',
    sort: true,
    headerClasses: 'score',

  },{
    dataField: 'netScore',
    text: 'Net',
    sort: true,
    headerClasses: 'netScore',

  }]

  return (
    <StyledContainer>
      <div className='gray_wrapper'>
      <h1 className="h1_leader">Team Leaderboard</h1>
      <StyledTable>
        <thead>
          <tr>
            <th className = 'rank'>Rank</th>
            <th className = 'playerInfo'>Team</th>
            <th className = 'grossScore'>Avg. Gross</th>
            <th className = 'netScore'>Avg. Net</th>
          </tr>
        </thead>

        <tbody>
          {teamsWithRank
            .map(({ team, rank }) => {
              return (
                <tr key={rank}>
                  <td className = 'rank'>{rank}</td>
                  <td className = 'playerInfo'>{ team.name }</td>
                  <td className = 'grossScore'>{ team.score }</td>
                  <td className = 'netScore'>{ team.score - team.handicap }</td>
                </tr>
              )
            })}
        </tbody>
      </StyledTable>
      </div>

      <div className='gray_wrapper'>
      <h1 className="h1_leader">Individual Leaderboard</h1>
      <div className="ui_container">
      <a href="#" onClick={targetUI} id="ui_toggle">Sort By <span className="not_active"> &#9660;</span><span className="is_active">&#9650; </span></a>
      <div className="ui_button_container">
      <a onClick={targetRank} id="target_rank" href="#"> Rank</a>
      <a onClick={targetFirstName} id="target_firstName" href="#">First Initial</a>
      <a onClick={targetLastName} id="target_lastName" href="#">Last Name</a>
      <a onClick={targetCoursePlayed} id="target_coursePlayed" href="#">Course Played</a>
      <a onClick={targetTeam} id="target_team" href="#">Team</a>
      <a onClick={targetHandicap} id="target_handicap" href="#">Handicap</a>
      <a onClick={targetGross} id="target_gross" href="#">Gross Score</a>
      <a onClick={targetNet} id="target_net" href="#">Net Score</a>
      </div>
      
      </div>
      <BootstrapTable keyField='rank' pagination={ paginationFactory() } data={ leaderboardData } columns={ leaderboardColumns } bodyClasses="override_table_css"  />


      {!playersWithRank.length && (
        <StyledNoPlayersPlaceholder>
          {hasSearchText ? "No matches found..." : "No players available yet..."}
        </StyledNoPlayersPlaceholder>
      )}
      </div>
    </StyledContainer>
  );
};
