import React from "react";

import Layout from "../containers/Leaderboard";
import NavBar from "../components/navbar";
import Helmet from 'react-helmet'

const LeaderboardPage = () => (
  <div>
          <Helmet>
          <body className='leaderboard_page' />
        </Helmet>
        <NavBar></NavBar>
        <div className="adjust_fixed_nav"></div>
    <Layout/>
  </div>
);

export default LeaderboardPage;
