import { Link } from "gatsby"
import React from "react"
import Helmet from 'react-helmet'


function showMobileNav() {
    console.log('hamburger')
    let detectNav = document.querySelector('nav');
    detectNav.classList.toggle('show')
    let detectSelf = document.querySelector('.hamburger');
    detectSelf.classList.toggle('is-active')

}


const NavBar = () => (

    <header className="fixed_nav" >


        <div className="logo" >
            <Link to="/home">
                <img className="img_responsive"
                    alt="chevron"
                    src="/salesforce.png" />
            </Link>
        </div>


        <nav className="nav_links desktop_nav">

            <ul>
                <li >
                    <Link to="/leaderboard"
                        > leaderboard </Link>
                </li >

                < li >
                    <Link to="https://docs.google.com/forms/d/e/1FAIpQLSc03j0oiMsdDcTGGaLOV4HBxSW_Zwgko0rV3kn3I9zlnhuT2Q/viewform"
                         > submit score </Link> </li >

                <li >
                    <Link to="/gallery"
                         > gallery </Link> </li >
                        
                        <li><Link to="/message_board"> message board </Link> </li>

                <li >
                    <Link to="/featured" > Featured talent & Charity  </Link> </li > </ul>

        </nav>


        <div className="hamburger hamburger--squeeze"
            onClick={
                showMobileNav
            } >
            <div className="hamburger-box" >
                <div className="hamburger-inner" >
                </div> </div> </div>  </header>
)


export default NavBar 