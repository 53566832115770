import React from "react";
import { UUID } from "common-types";
import "@blueprintjs/core/lib/css/blueprint.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import {
  ILeaderboard,
  ILeaderboardInput,
  IPlayer,
  IPlayerInput,
  ITeam,
  createLeaderboard,
  editLeaderboard,
  deleteLeaderboard,
  createPlayer,
  editPlayer,
  deletePlayer,
  deleteLeaderboardPlayers
} from "../../stateUtils";
import {
  getStore,
  setStore,
} from "../../utils/localStorage";
import Layout from "../../components/Layout_Leaderboard";

export type CreateLeaderboard = (leaderboardInput: ILeaderboardInput) => void;
export type EditLeaderboard = (id: UUID, leaderboardInput: ILeaderboardInput) => void;
export type DeleteLeaderboard = (id: UUID) => void;

export type CreatePlayer = (playerInput: IPlayerInput) => void;
export type EditPlayer = (id: UUID, playerInput: IPlayerInput) => void;
export type DeletePlayer = (id: UUID) => void;

// tslint:disable-next-line
interface IProps {

}

interface IState {
  leaderboards: ILeaderboard[];
  players: IPlayer[];
  teams: ITeam[];
}

export default class LayoutContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const store = getStore();

    this.state = store;
  }

  public componentDidUpdate = (_: IProps, prevState: IState) => {
    const { state } = this;

    if (state !== prevState) setStore(state);
  }

  public createLeaderboard: CreateLeaderboard = (leaderboardInput: ILeaderboardInput) => {
    this.setState({
      leaderboards: [
        ...this.state.leaderboards,
        createLeaderboard(leaderboardInput),
      ],
    });
  }

  public editLeaderboard: EditLeaderboard = (id, leaderboardInput) => {
    const { leaderboards } = this.state;

    const updatedLeaderboards = leaderboards.map(
      board => board.id !== id ? board : editLeaderboard(board, leaderboardInput)
    );

    this.setState({
      leaderboards: updatedLeaderboards,
    });
  }

  public deleteLeaderboard = (id: UUID) => {
    const { leaderboards, players } = this.state;

    this.setState({
      leaderboards: deleteLeaderboard(id, leaderboards),
      players: deleteLeaderboardPlayers(id, players),
    });
  }

  public createPlayer: CreatePlayer = playerInput => {
    this.setState({
      players: [
        ...this.state.players,
        createPlayer(playerInput),
      ],
    });
  }

  public editPlayer: EditPlayer = (id, playerInput) => {
    const { players } = this.state;

    const updatedPlayers = players.map(
      player => player.id !== id ? player : editPlayer(player, playerInput)
    );

    this.setState({
      players: updatedPlayers,
    });
  }

  public deletePlayer: DeletePlayer = (id: UUID) => {
    const { players } = this.state;

    this.setState({
      players: deletePlayer(id, players),
    });
  }

  public render() {
    const { leaderboards, players, teams } = this.state;
    return (
      <Layout
        leaderboards={leaderboards}
        players={players}
        teams={teams}
        createLeaderboard={this.createLeaderboard}
        editLeaderboard={this.editLeaderboard}
        deleteLeaderboard={this.deleteLeaderboard}
        createPlayer={this.createPlayer}
        editPlayer={this.editPlayer}
        deletePlayer={this.deletePlayer}
      />
    );
  }
}
